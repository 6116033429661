<template>
    <div class="talk_dialog">
        <div class="item" v-if="studentInfo">
            <div class="label"></div>
            <div class="content student_info">
                <el-image :src="studentInfo.avatar" fit="cover" />
                <div>
                    <div class="id">学员ID: {{ studentInfo.id }}</div>
                    <div class="nickname">微信昵称: {{ studentInfo.nickname }}</div>
                </div>
            </div>
        </div>
        <div class="item" v-if="staffInfo">
            <div class="label">归属运营</div>
            <div class="content staff_info">{{ staffInfo.name }}</div>
        </div>
        <div class="item" v-if="isShowIntent">
            <div class="label">意向管理</div>
            <div class="content intent">
                <div>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 5 }"
                        placeholder="请输入意向相关信息"
                        v-model="intentValue"
                        :disabled="!studentInfo"
                    />
                </div>
                <el-button @click="onCreateIntent" :disabled="!studentInfo" type="primary">添加意向</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { addIntent } from '@/js/api/saleApi.js'
import { getUserJudgeBelong, getTalkfunUid2User } from '@/js/api/userApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { mapGetters } from 'vuex'

export default {
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            studentInfo: null,
            staffInfo: null,

            isShowIntent: true,
            intentValue: '',
        }
    },
    created() {
        if (this.$route.query.source === 'login') {
            this.talkQuery = JSON.parse(window.sessionStorage.getItem('talkQuery'))
            this.getTalkfunUid2UserApi()
        } else if (this.userInfo) {
            this.talkQuery = this.$route.query
            this.getTalkfunUid2UserApi()
        } else {
            window.sessionStorage.setItem('talkQuery', JSON.stringify(this.$route.query))
            this.$router.push('/login?redirect=talkDialog')
        }

        this.updateIntentValue()
        window.talkMessageCb = () => {
            this.updateIntentValue()
        }
    },
    methods: {
        updateIntentValue() {
            const _msg = window.talkMessageInfo
            if (_msg) {
                const _actionMap = { qa: '提问', chat: '聊天' }
                this.intentValue = `${_actionMap[_msg.action]}: ${_msg.content}`
            }
        },
        onCreateIntent() {
            let params = {
                type: 'LIVE_QA',
                userId: this.studentInfo.id,
                target: this.talkQuery.courseId,
                content: this.intentValue,
                optId: this.staffInfo ? this.staffInfo.id : '',
            }
            params = filterQueryEmpty(params)
            console.log(params)

            addIntent(params).then(() => {
                this.$message.success('添加成功')
                this.isShowIntent = false
            })
        },
        getTalkfunUid2UserApi() {
            if (this.talkQuery.uid) {
                getTalkfunUid2User({ talkfunUid: this.talkQuery.uid }).then((res) => {
                    if (res.data) {
                        this.studentInfo = res.data
                        this.getUserJudgeBelongApi()
                    } else {
                        this.$message.warning('系统内无此用户信息')
                    }
                })
            } else {
                this.$message.warning('系统内无此用户信息')
            }
        },
        getUserJudgeBelongApi() {
            getUserJudgeBelong({
                userId: this.studentInfo.id,
                liveId: this.talkQuery.courseId,
            }).then((res) => {
                if (res.data.optStaff) {
                    this.staffInfo = res.data.optStaff
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.talk_dialog {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}
.item {
    display: flex;
    margin-bottom: 18px;
    .label {
        font-size: 14px;
        flex-shrink: 0;
        line-height: 32px;
    }
    .content {
        margin-left: 18px;
        flex-grow: 1;
    }
    .intent {
        .el-button {
            margin-top: 12px;
        }
    }
    .staff_info {
        line-height: 32px;
        font-size: 16px;
        font-weight: 700;
    }
    .student_info {
        margin-left: 0;
        display: flex;
        .el-image {
            width: 72px;
            height: 72px;
            background: #efefef;
            margin-right: 12px;
        }
        .id {
            font-size: 16px;
            line-height: 2em;
            font-weight: 700;
        }
        .nickname {
            font-size: 14px;
            line-height: 3em;
            font-weight: 700;
            color: #666;
        }
    }
}
</style>
